<template>
  <div class="collapseInfo">
    <el-collapse v-model="state.acIndex">
      <el-collapse-item name="1">
        <template #title> 文件信息 </template>
        <div class="collapseItem">
          <div class="label">合同类型：</div>
          <div>{{ props.baseInfo.contractTypeStr }}</div>
        </div>
        <div class="collapseItem">
          <div class="label">合同标题：</div>
          <div>{{ props.baseInfo.contractName }}</div>
        </div>
        <div class="collapseItem">
          <div class="label">状态：</div>
          <div>{{ getcontractStatus(props.baseInfo.contractStatus) }}</div>
        </div>
        <div class="collapseItem">
          <div class="label">签署截止日期：</div>
          <div>{{ props.baseInfo.contractDeadline }}</div>
        </div>
        <div class="collapseItem">
          <div class="label">合同发送日期：</div>
          <div>{{ props.baseInfo.initiateData }}</div>
        </div>
        <template v-if="props.basefilesList.length > 1">
          <div class="title mt-10">文件列表</div>
          <signatoryfile v-model:basefilesList="props.basefilesList"></signatoryfile>
        </template>
      </el-collapse-item>
      <!-- 仅签署展示 0默认 1签署 -->
      <el-collapse-item name="2" v-if="route.params.type == 1">
        <template #title>文件签署</template>
        <dev v-if="state.allSignType.length > 1" class="collapseItem">
          <div class="fs-0">签章类型:</div>
          <div>
            <el-tag v-for="signtype in state.showTags" type="" class="ml-2" effect="dark">{{signtype }}</el-tag>
          </div>
        </dev>
        <el-tabs v-model="state.activeTabsName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane v-for="signType in state.allSignType" :label="signType.name" :name="signType.key">
            <template v-if="signType.key == 2 || signtype == 5">
              <div class="title mb-10">个人签名</div>
              <signature class="mb-10" @Emit="signatureEmit" @nowpositionEmit="nowpositionEmit"></signature>
              <!-- 0 签章，1 签名，2 日期，3 骑缝章 -->
              <div class="subtitle mb-30">
                已添加{{ getlength(state.signatures, 1) }}个签名
              </div>
            </template>
            <!-- 个人无签章 -->
            <template v-if="userInfo.sysCompany && (signType.key == 1 || signType.key == 3)">
              <div class="title mb-10">选择签章</div>
              <signs class="mb-10" @Emit="signsEmit" @nowpositionEmit="nowpositionEmit"></signs>
              <div class="subtitle mb-30">已添加{{ getlength(state.signs, 0) }}个签章</div>
            </template>
            <template v-if="signType.key == 4">
              <!-- <div class="title mb-10 dp-f jc-sb">
                <div>签署日期</div>
                <oabutton width="64" height="32" style="border-radius: 2px" title="切换" CSStype="2" class="ml-14"
                  @buttonclick="switchDateBTClick" />
              </div> -->
              <signtime ref="signtimeRef" class="mb-10" @Emit="signtimeEmit" @nowpositionEmit="nowpositionEmit"></signtime>
              <div class="subtitle mb-20">
                已添加{{ getlength(state.signtimes, 2) }}个日期
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
        <!-- {{ props.basefilesList[state.basefilesIndex] }} -->
        <!-- v-if="props.basefilesList[state.basefilesIndex].isSetPagInSeal==1" -->
        <!-- {{ props.basefilesList[0].pages }} -->
        <!-- <div class="title mb-10 dp-f jc-sb">
          <div>骑缝章</div>
          <el-popover placement="left" :width="400" :visible="state.PagInSealvisible">
            <template #reference>
              <oabutton @buttonclick="state.PagInSealvisible=!state.PagInSealvisible" width="118" height="42" style="border-radius: 2px" title="添加骑缝章" CSStype="2" class="ml-14"  />
            </template>
            <PagInSealbox @cancel="state.PagInSealvisible=false" @Emit="state.PagInSealvisible=false,unref(seamingRef).show(props.basefilesList[0].pages[0].fileUrl)" />
          </el-popover>
        </div> -->
      </el-collapse-item>
      <el-collapse-item name="3">
        <template #title> 操作记录 </template>
        <records></records>
      </el-collapse-item>
    </el-collapse>
  </div>
  <seaming ref="seamingRef"></seaming>
  <setsignlocation ref="setsignlocationRef" @Emit="setSignLocationEmit"></setsignlocation>
</template>
<script setup>
import { ref, unref, reactive, defineEmits, computed, onMounted, nextTick,watch } from "vue";
import signatoryfile from "./collapseInfo/signatoryfile.vue"; //文件列表
import signature from "./collapseInfo/signature.vue"; //个人签名
import signs from "./collapseInfo/signs.vue"; //选择签章
import signtime from "./collapseInfo/signtime.vue"; //签署日期
import records from "./collapseInfo/records.vue"; //操作记录
import PagInSealbox from "./collapseInfo/PagInSealbox.vue"; //骑缝章选择框
import seaming from './dialog/seaming.vue'//骑缝章
import setsignlocation from './dialog/setsignlocation.vue'//指定签署位置弹框
import { getcontractStatus } from "@/utils/server/getcode.js"
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { debounce, arrDeteleItem } from "@/utils/util.js";
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { mul, div } from "@/utils/server/format.js"
import {configu} from '@/utils/config'
import qs from 'qs'
const route = useRoute()
const store = useStore();
const emit = defineEmits(["Emit"]);
watch(
  () => store.state.MousepositionData,
  (value, oldValue) => {
    console.log('watch触发')
    // console.log('store.state.MousepositionData',value, oldValue)
    if(store.state.MouseType==0){
      signsEmit(store.state.MouseData,2)
    }else if(store.state.MouseType==1){
      signatureEmit(store.state.MouseData,2)
    }else if(store.state.MouseType==2){
      signtimeEmit(store.state.MouseData,2)
    }
  }, {deep:true}
)
const signtimeRef = ref();
const seamingRef = ref()//签署意愿ref
const setsignlocationRef = ref()//指定签署位置弹框ref
const props = defineProps({
  baseInfo: {
    type: Object,
  },
  basefilesList: {
    type: Array,
  },
});
const userInfo = JSON.parse(window.sessionStorage.user).user_info
const state = reactive({
  activeTabsName: -1,
  acIndex: ["1", "2"], //默认打开的折叠面板
  basefilesIndex: computed(() => {
    return store.state.sign.basefilesIndex
  }),//当前展示文件下标
  PagInSealvisible: false,//骑缝章显示
  signModel: {
    signatureY: 0,
    signatureX: 0,
    top: 0,
    left: 0,
    basefilesIndex: computed(() => {
      return store.state.sign.basefilesIndex
    }),
  },//印章默认信息
  checkSignData: [],
  showTags: [],
  allSignType: [],
  // 个人签名签署数据
  signatures: [],
  // 签章签署数据
  signs: [],
  // 日期签署数据
  signtimes: [],
  //当前签署人所有指定签署位置 0 签章，1 签名，2 日期，3 骑缝章
  sealpositionDatas: computed(() => {
    return store.state.sign.sealpositionDatas//签章
  }),
  signaturepositionDatas: computed(() => {
    return store.state.sign.signaturepositionDatas//签名
  }),
  datepositionDatas: computed(() => {
    return store.state.sign.datepositionDatas//日期
  }),
  allSignaturePositionDatas: computed(() => {
    return store.state.sign.allSignaturePositionDatas
  })
});

watch(() => store.state.sign.checkTime, (value, oldValue) => {
  console.log("show watch for test", store.state.realName.type)
  if (state.allSignType.length > 0) {
    return
  }
  // let 
  state.allSignType = [];
  state.showTags = [];
  let tmpCheck = {}
  if (!userInfo.sysCompany)  { //个人 
    for (let i = 0; i < state.allSignaturePositionDatas.length; i++) {
      if ( state.allSignaturePositionDatas[i].type == 1 ) {
        state.showTags.push('签名')
        tmpCheck['签名'] = 0 
        state.allSignType.push({key:2, name: '签名'})
      } else if (state.allSignaturePositionDatas[i].type == 2) {
        state.showTags.push('日期')
        tmpCheck['日期'] = 0 
        state.allSignType.push({key:4, name: '日期'})
      }
    }
  } else {
    let haveCmpSeal = false; 
    let haveLegalSeal = false;
    let haveDateSeal = false;
    let haveOptSeal = false;
    for (let i = 0; i < state.allSignaturePositionDatas.length; i++) {
      if ( state.allSignaturePositionDatas[i].signingRequirements == 0 ) {
        state.showTags.push('企业章')
        tmpCheck['企业章'] = 0
        haveCmpSeal = true
        // state.allSignType.push({key:1, name: '企业章'})
      } else if ( state.allSignaturePositionDatas[i].signingRequirements == 1 ) {
        haveOptSeal = true
        state.showTags.push('经办人签名')
        tmpCheck['经办人签名'] = 0 
        // state.allSignType.push({key:2, name: '经办人签名'})
      } else if ( state.allSignaturePositionDatas[i].signingRequirements == 2 ) {
        state.showTags.push('法人章')
        tmpCheck['法人章'] = 0 
        // state.allSignType.push({key:3, name: '法人章'})
        haveLegalSeal = true
      } else if (state.allSignaturePositionDatas[i].type == 2) {
        state.showTags.push('日期')
        tmpCheck['日期'] = 0 
        haveDateSeal = true
        // state.allSignType.push({key:4, name: '日期'})
      }
    }
    if ( haveCmpSeal && haveLegalSeal) {
      state.allSignType.push({key: 1, name: '企业章和法人章'})
    } else if (haveCmpSeal) {
      state.allSignType.push({key: 1, name: '企业章'})
    } else if (haveLegalSeal) {
      state.allSignType.push({key: 3, name: '法人章'})
    }
    if (haveOptSeal)
      state.allSignType.push({key:2, name: '经办人签名'})
    if (haveDateSeal)
      state.allSignType.push({key:4, name: '日期'})
  }
  if (state.allSignType.length > 0) 
    state.activeTabsName = state.allSignType[0].key

  if (state.checkSignData.length == 0) {
    // console.log("check 1")
    //初始化检查数据
    for (let i = 0; i < props.basefilesList.length; i++) {
      // let signdata = {}
      // let check = tmpCheck
      // signdata.allSign = false
      // signdata.checkData = tmpCheck;
      state.checkSignData.push(JSON.parse(JSON.stringify(tmpCheck)))
    }
  } else {
    // console.log("check 2")
  }
  // console.log('props.basefilesList',props.basefilesList)
  // console.log('state.basefilesIndex',state.basefilesIndex)
  // console.log("allSigntype: ", state.allSignType, state.showTags)
  // console.log("checkData: ",state.checkSignData)
})
// state.basefilesList[state.basefilesIndex]
  // isSetSignature 签章位置 0 不指定 1 指定
  // isSetAutograph 签名位置
  // isSetDate 日期位置
const nowpositionEmit=(el,type)=>{
  let nowpositionData = getnowpositionData(type)
  // 是否存在指定位置
  if (nowpositionData) {
    if(type == 0){
      signsEmit(el)
    }else if(type==1){
      signatureEmit(el)
    }else if(type==2){
      signtimeEmit(el)
    }
  } else {
   // 不存在情况 如果要求指定进行判断
    console.log('props.basefilesList[state.basefilesIndex]22',props.basefilesList[state.basefilesIndex])
    if(type==0){
      if(props.basefilesList[state.basefilesIndex].isSetSignature==1){
        handleMessage('已指定印章签署位置,不需手动添加!', 'info')
        return  
      }
    }else if(type==1){
      if(props.basefilesList[state.basefilesIndex].isSetAutograph==1){
        handleMessage('已指定签名签署位置,不需手动添加!', 'info')
        return  
      }
    }else if(type==2){
      if(props.basefilesList[state.basefilesIndex].isSetDate==1){
        handleMessage('已指定日期签署位置,不需手动添加!', 'info')
        return  
      }
    }
    store.state.MouseType=type
    store.state.Mouseis=true
  }
}
// 重新设置拖动组件位置信息
const SetoffsetData=(data)=>{
  // 0 签章，1 签名，2 日期，3 骑缝章
  //0 签章 width: 155,height: 155,
  //1 签名 width: 134,height: 34,
  //2 日期 width: 176,height: 38,
  let xdeviation//x 偏差
  let ydeviation//y 偏差
  if(store.state.MouseType==0){
    xdeviation=55
    ydeviation=55
  }else if(store.state.MouseType==1){
    xdeviation=67
    ydeviation=17
  }else if(store.state.MouseType==2){
    xdeviation=88
    ydeviation=19
  }
  
  let left=store.state.MousepositionData.data.offsetX-xdeviation
  let top=store.state.MousepositionData.data.offsetY-ydeviation
  // console.log('store.state.MousepositionData.data.offsetX',store.state.MousepositionData.data.offsetX,store.state.MousepositionData.parentW,store.state.MousepositionData.data.offsetX+xdeviation)
  // 如果超出范围则重制
  if((store.state.MousepositionData.data.offsetX+xdeviation)>store.state.MousepositionData.parentW){
    left=store.state.MousepositionData.parentW-(xdeviation*2)
  }
  if((store.state.MousepositionData.data.offsetX-xdeviation)<0){
    left=0
  }
  if((store.state.MousepositionData.data.offsetY+ydeviation)>store.state.MousepositionData.parentH){
    top=store.state.MousepositionData.parentH-(ydeviation*2)
  }
  if((store.state.MousepositionData.data.offsetY-ydeviation)<0){
    top=0
  }
  data.signatureY=mul(div((top + data.height),store.state.MousepositionData.parentH),100).toFixed(3)
  data.signatureX=mul(div(left,store.state.MousepositionData.parentW),100).toFixed(3)
  if (data.signatureY > 100) {
    data.signatureY = 100
  }
  console.log("(x, y)",data, data.signatureX, data.signatureY)
  data.top=top
  data.left=left
  data.pageIndex=store.state.MousepositionData.index+1
}
// 获取签署数据长度  0 签章，1 签名，2 日期，3 骑缝章
const getlength = (data, type) => {
  let length = 0
  for (let i = 0; i < data.length; i++) {
    if (!data[i].ishide && data[i].basefilesIndex == state.basefilesIndex)
      length++
  }
  console.log("get length1: ", length)
  // 获取指定印章的数据长度
  if (type == 0) {
    state.sealpositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[state.basefilesIndex].id) {
        length++
      }
    })
  } else if (type == 1) {
    state.signaturepositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[state.basefilesIndex].id) {
        length++
      }
    })
  } else if (type == 2) {
    state.datepositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[state.basefilesIndex].id) {
        length++
      }
    })
  }
  console.log("get length2: ", length)
  return length
}
// 判断当前是否存在指定的签章位置 0 签章，1 签名，2 日期，3 骑缝章
const getnowpositionData = (type) => {
  let data = []
  let positionData = []
  if (type == 2) {
    positionData = state.datepositionDatas
  } else if (type == 1) {
    positionData = state.signaturepositionDatas
  } else {
    positionData = state.sealpositionDatas
  }
  positionData.forEach(item => {
    if (item.fileId == props.basefilesList[state.basefilesIndex].id && !item.nowSignData) {
      data.push(item)
    }
  });
  if (data.length == 0) {
    return false
  } else {
    return data
  }
}
// 添加签署日期
const signtimeEmit = (el,type=1) => {
  console.log('添加签署日期',el,type)
  let nowpositionData = getnowpositionData(2)
  let data = {
    ...state.signModel,
    timeId: new Date().getTime(),
    sealId: '',
    name: el.NowDate,
    width: 176,
    height: 38,
    pageIndex: store.state.sign.currentPage,
    type: 2,
    isDraggable: true,
  };
  console.log('data1',data)
  if (el.showType != 1) {
    data.name = el.NowDate + "  " + el.NowTime;
  }
  if(type==2){
    SetoffsetData(data)
  }
  // console.log('data22',nowpositionData,data)
  // console.log('prop.baseInfo',props.baseInfo)
  // props.baseInfo.isSetSignature
  if (nowpositionData) {
    unref(setsignlocationRef).show('设置签署日期', nowpositionData, data)
  } else {
    state.signtimes.push(JSON.parse(JSON.stringify(data)));
    if (state.checkSignData[state.basefilesIndex]['日期'] != null)  {
      state.checkSignData[state.basefilesIndex]['日期']++ 
    }
  }
};
// 添加签章
const signsEmit = (el,type=1) => {
  httpToken({
    method: "post",
    url: '/initiate-contract/contract/isOKSignaturePosition',
    data: qs.stringify({
      seal_id: el.id,
      contract_id: route.params.id
    })
  }).then(res => {
    if (!res.data) {
      handleMessage('当前印章不在签署要求中', 'info')
      return
    } else {
      let arr = []
      let nowpositionData = getnowpositionData(0)
      if (nowpositionData) {
        if (el.sealType == 4) {
          for (let i of nowpositionData) {
            if (i.sealType == 2) {
              arr.push(i)
            }
          }
        } else {
          for (let i of nowpositionData) {
            if (i.sealType != 2) {
              arr.push(i)
            }
          }
        }
      }
      let data = {
        ...state.signModel,
        timeId: new Date().getTime(),
        sealId: el.id,
        name: el.name,
        url: el.url,
        sealType: el.sealType,
        width: el.sealType === 4 ? configu.legalSealHeight : configu.entSealHeight,
        height: el.sealType === 4 ? configu.legalSealHeight: configu.entSealHeight,
        pageIndex: store.state.sign.currentPage,
        type: el.sealType === 4 ? 3 : 1,
        isDraggable: true,
      }
      if(type==2){
        SetoffsetData(data)
      }
      if (nowpositionData) {
        unref(setsignlocationRef).show('设置签章位置', arr, data)
      } else {
        state.signs.push(JSON.parse(JSON.stringify(data)));
        if (el.sealType == 3 || el.sealType == 5) {
          if (state.checkSignData[state.basefilesIndex]['企业章'] != null)  {
            state.checkSignData[state.basefilesIndex]['企业章']++
          }
        } else if (el.sealType == 4) {
          if (state.checkSignData[state.basefilesIndex]['法人章'] != null)  {
            state.checkSignData[state.basefilesIndex]['法人章']++
          }
        }
      }
    }
  })
  console.log("checkSignData: ", state.checkSignData)
};
// 添加个人签名
const signatureEmit = (el,type=1) => {
  // console.log('添加个人签名',el)
  let nowpositionData = getnowpositionData(1)
  let data = {
    ...state.signModel,
    signNameType:el.type,
    timeId: new Date().getTime(),
    sealId: el.id,
    name: el.name,
    url: el.url,
    width: configu.personSignWidth,
    height: configu.personSignHeight,
    pageIndex: store.state.sign.currentPage,
    type: 0,
    isDraggable: true,
  }
  if(type==2){
    SetoffsetData(data)
  }
  console.log('添加个人签名222',data)
  if (nowpositionData) {
    unref(setsignlocationRef).show('设置个人签名', nowpositionData, data)
  } else {
    state.signatures.push(JSON.parse(JSON.stringify(data))); 
    if (!userInfo.sysCompany) {
      if (state.checkSignData[state.basefilesIndex]["签名"] != null) {
        state.checkSignData[state.basefilesIndex]["签名"]++
      }
    } else {
      if (state.checkSignData[state.basefilesIndex]["经办人签名"] != null) {
        state.checkSignData[state.basefilesIndex]["经办人签名"]++
      }
    }
  }
};
/*
const setSignLocationEmit = (signData) => {
  console.log("userinfo:", userInfo)
  console.log("signData:", signData)
  for (let i = 0; i < signData.length; i++) {
    if (signData[i].nowSignData == null || signData[i].nowSignData == false) {
      break;
    }
    switch(signData[i].nowSignData.type) {
      case 0:
        if (!userInfo.sysCompany) {
          if (state.checkSignData[state.basefilesIndex]["签名"] != null) {
            state.checkSignData[state.basefilesIndex]["签名"]++
          }
        } else {
          if (state.checkSignData[state.basefilesIndex]["经办人签名"] != null) {
            state.checkSignData[state.basefilesIndex]["经办人签名"]++
          }
        }
        break;
      case 1:
        if (signData[i].nowSignData.sealType == 3 || signData[i].nowSignData.sealType == 5) {
          if (state.checkSignData[state.basefilesIndex]['企业章'] != null)  {
            state.checkSignData[state.basefilesIndex]['企业章']++
          }
        } else if (signData[i].nowSignData.sealType == 4) {
          if (state.checkSignData[state.basefilesIndex]['法人章'] != null)  {
            state.checkSignData[state.basefilesIndex]['法人章']++
          }
        }
        break;
      case 2:
        if (state.checkSignData[state.basefilesIndex]['日期'] != null)  {
          state.checkSignData[state.basefilesIndex]['日期']++ 
        }
        break;
    }
  }
  console.log("setSignLocationEmit:", state.checkSignData)
}
*/
// 复制操作执行
const copyExecute = (el, pageIndex) => {
  nextTick(() => {
    let data = { ...el };
    if (pageIndex)
      data.pageIndex = pageIndex
    data.timeId = new Date().getTime();
    switch (el.type) {
      case 0:
        state.signatures.push(JSON.parse(JSON.stringify(data)));
        if (!userInfo.sysCompany) {
          if (state.checkSignData[state.basefilesIndex]["签名"] != null) {
            state.checkSignData[state.basefilesIndex]["签名"]++
          }
        } else {
          if (state.checkSignData[state.basefilesIndex]["经办人签名"] != null) {
            state.checkSignData[state.basefilesIndex]["经办人签名"]++
          }
        }
        break;
      case 1:
        state.signs.push(JSON.parse(JSON.stringify(data)));
        if (el.sealType == 3 || el.sealType == 5) {
          if (state.checkSignData[state.basefilesIndex]['企业章'] != null)  {
            state.checkSignData[state.basefilesIndex]['企业章']++
          }
        } else if (el.sealType == 4) {
          if (state.checkSignData[state.basefilesIndex]['法人章'] != null)  {
            state.checkSignData[state.basefilesIndex]['法人章']++
          }
        }
        break;
      case 2:
        state.signtimes.push(JSON.parse(JSON.stringify(data)));
        if (state.checkSignData[state.basefilesIndex]['日期'] != null)  {
          state.checkSignData[state.basefilesIndex]['日期']++ 
        }
        break;
    }
  });
};
// 复制
const copy = debounce((el, pageIndex) => { copyExecute(el, pageIndex) }, 200);
// 删除
const detele = ((el) => {
  // console.log("el el el: ", el)
  setTimeout(() => {
    switch (el.type) {
      case 0:
        arrDeteleItem(state.signatures, el, 'timeId', 2)
        if (store.state.realName.type == '1') {
          if (state.checkSignData[state.basefilesIndex]["签名"] != null) {
            state.checkSignData[state.basefilesIndex]["签名"]--
          }
        } else {
          if (state.checkSignData[state.basefilesIndex]["经办人签名"] != null) {
            state.checkSignData[state.basefilesIndex]["经办人签名"]--
          }
        }
        break;
     case 1:
        // console.log("el el el: ", el)
        arrDeteleItem(state.signs, el, 'timeId', 2)
        if ((el.sealType == 3 || el.sealType == 5) && state.checkSignData[state.basefilesIndex]['企业章'] != null) {
          state.checkSignData[state.basefilesIndex]['企业章']--
        } 
        if ((el.sealType == 4) && state.checkSignData[state.basefilesIndex]['法人章'] != null) {
          state.checkSignData[state.basefilesIndex]['法人章']--
        }
        break;
      case 2:
        arrDeteleItem(state.signtimes, el, 'timeId', 2)
        if (state.checkSignData[state.basefilesIndex]["日期"] != null) {
          state.checkSignData[state.basefilesIndex]["日期"]--
        }
        break;
    }
  }, 100)
})
const checkAddSignData = () => {
 


  for (let i = 0; i < state.checkSignData.length; i++) {
    //计算固定位置数量
    let sealnum = 0, legalnum = 0, handlernum = 0, signnum = 0, datenum = 0

    state.sealpositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[i].id) {
        if (item.nowSignData.sealType == 3 || item.nowSignData.sealType == 5) {
          sealnum++
        } else if ( item.nowSignData.sealType == 4 ){
          legalnum++
        }
      }
    })
    state.signaturepositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[i].id) {
        if (userInfo.sysCompany) {
        handlernum++
        } else {
        signnum++
        }
      }
    })

    state.datepositionDatas.forEach(item => {
      if (item.nowSignData && item.fileId == props.basefilesList[i].id) {
        datenum++ 
      }
    })
    console.log("sign nums: ", sealnum, legalnum, handlernum, signnum, datenum)
    let tmp = state.checkSignData[i]
    if (tmp['企业章'] != null && tmp['企业章'] + sealnum <= 0) {
    handleMessage("文件：" + props.basefilesList[i].fileChname + "未添加企业章")
    return false
    }

    if (tmp['法人章'] != null && tmp['法人章'] + legalnum <= 0) {
    handleMessage("文件：" + props.basefilesList[i].fileChname + "未添加法人章")
    return false
    }

    if (tmp['经办人签名'] != null && tmp['经办人签名'] + handlernum <= 0) {
    handleMessage("文件：" + props.basefilesList[i].fileChname + "未添加经办人签名")
    return false
    }

    if (tmp['签名'] != null && tmp['签名'] + signnum <= 0) {
    handleMessage("文件：" + props.basefilesList[i].fileChname + "未添加签名")
    return false
    }

    if (tmp['日期'] != null && tmp['日期'] + datenum <= 0) {
    handleMessage("文件：" + props.basefilesList[i].fileChname + "未添加日期")
    return false
    }
  }
  return true 
}
defineExpose({
  signatures: state.signatures,
  signs: state.signs,
  signtimes: state.signtimes,
  checkSignData: state.checkSignData,
  copy, copyExecute, detele, checkAddSignData
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置

.subtitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.collapseInfo {
  width: 100%;
  height: 100%;
  background: #fcfcfc;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .collapseItem {
    display: flex;
    margin-bottom: 4px;

    .label {
      width: 120px;
      flex-shrink: 0;
      color: #999999;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 500;
  }
}

::v-deep .el-collapse {
  height: 100%;
  overflow: auto;

  .el-collapse-item__header {
    padding: 0 20px;
    background-color: transparent;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }

  .el-collapse-item__wrap {
    padding: 0 20px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    //background-color: #8c8c8c;
    background-color: rgba(210, 210, 210, 1);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(236, 236, 236, 1);
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border: 0;
  }
}
</style>