<template>
  <div class="signatory">
    <div class="input_box w-280 ai-c">
      <div class="fs-0">选择文件：</div>
      <el-select placeholder=请选择文件 v-model="state.searchKey">
        <el-option v-for="(el, ind) in props.basefilesList" :key="ind" :value="el.id" :label="el.fileChname"  @click="selectFile(ind)"/>
      </el-select>
    </div>
  </div>
</template>
<script setup>
import { ref ,reactive, defineEmits } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  basefilesList: {
    type: Array,
  },
});
let state = reactive({
  // searchKey:props.basefilesList?props.basefilesList[0].fileChname:'',
  searchKey:'',
  filesInd:[],
});
state.searchKey=props.basefilesList[0].id
// 选择文件
const selectFile=(ind)=>{
  store.commit('sign/setbaseFilesIndex',ind)
  // state.filesInd.includes//没有选择过文件调取位置信息
  state.filesInd.push(ind)
}
defineExpose({
})
</script>
<style lang="scss" scoped>
</style>