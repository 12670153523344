<template>
  <div class="PagInSealbox">
    <div class="fs-20 mb-10">添加骑缝章</div>
    <div class="mb-10">选择签章</div>
    <el-radio-group v-model="state.radio" class="mb-10">
      <el-radio :label="el" v-for="(el,ind) in state.signsDatas" :key="ind">{{el.name }}</el-radio>
    </el-radio-group>
    <div class="w100 dp-f fd-rr">
      <oabutton width="60" height="30" style="border-radius: 2px" title="确定" CSStype="2" @buttonclick="saveData()"/>
      <oabutton width="60" height="30" style="border-radius: 2px" title="取消" CSStype="1" class="mr-14" @buttonclick="cancel()" />
    </div>
    <!-- @buttonclick="unref(seamingRef).show(props.basefilesList[0].pages[0].fileUrl)" -->
  </div>
</template>
<script setup>
import { ref, unref, reactive, defineEmits, computed } from "vue";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { useStore } from "vuex";
const emit = defineEmits(['Emit','cancel'])
const store = useStore();
const state = reactive({
  radio: '',
  signsDatas: computed(() => {
    return store.state.sign.signsDatas
  }),
})
const saveData=()=>{
  if(!state.radio){
    handleMessage('请选择签章')
    return
  }
  emit('Emit')
}
const cancel=()=>{
emit('cancel')
}
</script>
<style lang="scss" scoped>
.PagInSealbox {
  padding: 8px 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
}
</style>