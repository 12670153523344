<template>
  <el-dialog class="operation_dialog bgac_dialog" v-model="dialogVisible" :title="dialogtitle"
    :close-on-click-modal="false" width="1190px">
    <div class="imgbox w100 dp-f jc-c">
      <img :src="state.imgurl" class="page pt-r">
      <!-- <img class="page pt-r" src="@/assets/img/initiateContract/20230113170907.png"> -->
      <div class="seamingItem pt-r" :style="{ 'z-index': 99 - ind }" v-for="ind in state.seamingslength" :key="ind"></div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="batchSeal()"
          v-model:loading="dialogloading"/>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false"/>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, defineEmits } from 'vue'
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  imgurl: '',
  seamingslength: 3,
})
const show = ((imgurl) => {
  // console.log('imgurl',imgurl)
  dialogtitle.value = '添加骑缝章'
  dialogVisible.value = true
  state.imgurl=imgurl
})

defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
::v-deep .el-input,
.el-input {
  height: 48px;
}

.imgbox {
  .page {
    z-index: 100;
    width: 580px;
    height: 760px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
  }

  .seamingItem {
    width: 54px;
    height: 760px;
    background: #fff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
  }
}
</style>