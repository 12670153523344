<template>
  <el-dialog v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false" width="730px">
    <!-- {{ state.Notice[0] }} -->
    <el-table ref="tableRef" :data="state.Notice" class="mb-20" :header-cell-style="{
      background: '#EAEAEA',
      color: config.table.color,
      fontWeight: config.table.fontWeight,
      fontSize: config.table.fontSize,
    }" row-key="id" @selection-change="selectionChange">
      <el-table-column :align="'center'" type="selection" width="55" />
      <el-table-column :align="'center'" type="index" label="序号" width="80" />
      <el-table-column :align="'center'" prop="name" label="印章名称" />
      <el-table-column :align="'center'" prop="pageIndex" label="页数" />
      <el-table-column :align="'center'" label="X轴">
        <template #default="scope">
					<div class="dp-fc">
						{{ scope.row.signatureX }}%
					</div>
				</template>
			</el-table-column>
      <el-table-column :align="'center'" label="Y轴">
        <template #default="scope">
					<div class="dp-fc">
						{{ scope.row.signatureY }}%
					</div>
				</template>
			</el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="saveData()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, watch,computed } from 'vue'
import { useStore } from "vuex";
import {handleMessage} from "@/utils/server/confirm.js"
// import { getXialaListdata2 } from "@/utils/server/selectdata.js"
const store = useStore();
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const state = reactive({
  Notice:[],
  selectionData: [],
  nowsign:{},//当前印章数据
})
const saveData=()=>{
  if(state.selectionData.length==0){
    handleMessage('请先选择签署数据')
  }
  console.log('state.nowsign',state.nowsign)
  state.selectionData.forEach(item=>{
    item.nowSignData=state.nowsign
  })
  dialogVisible.value=false
  store.commit('sign/setcheckTime',new Date())
  emit('Emit',state.selectionData)
}
// 表格选中事件
const selectionChange = (val) => {
  state.selectionData = val
}
// 0 签章，1 签名，2 日期，3 骑缝章
const show = ((title,data,nowsign) => {
  state.Notice=data
  state.nowsign=nowsign
  dialogVisible.value = true
  dialogtitle.value=title
})

defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
::v-deep .el-input,
.el-input {
  height: 48px;
}

::v-deep .el-dialog__title,
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333 !important;
}</style>