<template>
  <div class="records">
    <div class="w-114 mb-20">
      <el-select placeholder=请选择签章类型 v-model="state.searchKey" @change="getData()">
        <el-option v-for="(el,ind) in state.TypeList" :key="ind" :value="el.value" :label="el.label" />
      </el-select>
    </div>
    <div class="recordItem dp-f jc-sb mb-10" v-for="(el,ind) in state.recordsData" :key="ind">
      {{el}}
    </div>
  </div>
</template>
<script setup>
import {chnameDesensitization,phoneDesensitization} from "@/hooks/desensitization.js"
import { reactive, defineEmits } from "vue";
import {getXialaListdata2} from "@/utils/server/selectdata.js"
import { httpToken } from "@/utils/request";
import { useRoute } from "vue-router";
import qs from "qs";
const emit = defineEmits(['Emit'])
const route = useRoute()
const state = reactive({
  searchKey:'',
  TypeList:getXialaListdata2(['所有记录','查看记录','签署记录'],['','查看合同','签署合同']),
  recordsData: [],// 操作记录数据列表
});
// const DesChname=chnameDesensitization(JSON.parse(window.sessionStorage.user).user_info.chnname)
// const DesPhone=phoneDesensitization(JSON.parse(window.sessionStorage.user).user_info.phone)
const getData=()=>{
  state.recordsData=[]
  httpToken({
    method: "post",
    url: '/admin/messagepush/operateLog',
    data: qs.stringify({
      'id': route.params.id,
      'messageTitle':state.searchKey,
    })
  }).then((res) => {
    console.log('获取所有操作记录', res)
    // res.data.forEach(item => {
      
    // });
    state.recordsData=res.data
  })
}
getData()
</script>
<style lang="scss" scoped>
.records{
  ::v-deep .el-input{
    height: 34px;
    .el-input__wrapper{
      border-radius: 18px;
    }
    // border-radius: 18px;
    // border: 1px solid #979797;
  }
}
</style>