<template>
  <div class="signs">
    <div class="dp-f jc-sb mb-10">
      <div class="input_box w-280">
        <div class="fs-0">签章类型：</div>
        <el-select placeholder=请选择签章类型 v-model="state.searchKey" @change="SelectSigns">
          <el-option v-for="(el, ind) in state.signTypeList" :key="ind" :value="el.value" :label="el.label" />
        </el-select>
      </div>
    </div>
    <div class="showbox dp-f">
      <div v-for="(el, ind) in showSignFilter" :key="ind" class="itembox cu-p" :class="el.selectedState ? 'itemboxac' : ''" @click="props.type==2?debounceClick(el):''"
          @mousedown="mouseFun($event,el)">
        <div class="imgbox dp-fc">
          <img :src="el.url">
          <img class="pt-a selectedIcon" src="@/assets/img/general/selectac.png" v-if="el.selectedState">
        </div>
        <div class="name">{{ el.name }}</div>
      </div>
      <div class="c-999" v-if="state.signs.length==0">
        暂无此类印章请联系管理添加
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, unref, reactive, defineEmits,defineProps, computed } from "vue";
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
import {searchSealType} from '@/utils/server/selectdata.js'
import { debounce } from "@/utils/util.js";
import { httpToken } from "@/utils/request";
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
import { useStore } from "vuex";
const props = defineProps({
  type: {
    type: Number,
    default() {
      return 1//1默认 2批量签署
    },
  },
});
const store = useStore();
const mouseFun=(e,el)=>{
  // getnowpositionData
  // console.log('鼠标事件',e,el)
//0 签章 width: 110,height: 110,
  store.state.Mouseleft=(e.clientX - 55)
  store.state.Mousetop=(e.clientY - 55)
  store.state.MouseData=el
  emit('nowpositionEmit',el,0)
  // store.state.Mouseis=true
}
const emit = defineEmits(['Emit','nowpositionEmit'])
const state = reactive({
  searchKey: null,//搜索绑定值
  signTypeList:searchSealType(),//印章类型下拉 业务章 总章
  signs: [
    // {url:require('@/assets/img/general/sign.png'),name:'西安刻章有限公司',selectedState:true},
  ],// 个人签名签署数据
});
// 选择印章
const SelectSigns = (el) => {
  console.log('选择印章', el)
  // getSignsData()
}

const showSignFilter = computed(() => {
  return state.signs.filter(item => {
    return state.searchKey == null || item.sealType == state.searchKey
  })
})

// 获取签章数据
const getSignsData = () => {
  state.signs = []
  queryResourceTable('/initiate-contract/contract/availableSeal', qs.stringify({
    type: state.searchKey
  })).then((res) => {
    console.log('获取签章数据', res)
    if(res.data.length==0){
      if(state.searchKey==''||state.searchKey==null){
        handleMessage('请先前往控制台完成印章添加')
      }
    }
    res.data.forEach(item => {
      state.signs.push({
        id: item.file.id,
        name: item.seal.sealName,
        url: item.file.fileUrl,
        sealType:item.seal.sealType,
        selectedState: false,
      })
    })
    if(props.type==2){
      if(state.signs.length>0){
        emit('Emit', JSON.parse(JSON.stringify(state.signs[0])))
        state.signs[0].selectedState=true
      }
    }
    store.commit('sign/setsignsDatas', state.signs)
  })
}
getSignsData()
const debounceClick = debounce((el) => { 
  emit('Emit', JSON.parse(JSON.stringify(el)))
  if(props.type==2){
    state.signs.forEach(item=>{
      item.selectedState=false
    })
  }
  el.selectedState = true 
}, 200)

</script>
<style lang="scss" scoped>
.signs {
  .showbox {
    min-height: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    border: 1px solid #E6E6E6;

    :nth-child(3n).itembox {
      margin-right: 0;
    }

    .itembox {
      width: 32%;
      margin-right: 2%;

      .imgbox {
        width: 100%;
        border: 1px solid #E6E6E6;
        position: relative;

        .selectedIcon {
          right: 0;
          bottom: 0;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .name {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
    }

    .itemboxac {
      .imgbox {
        border: 1px solid #EAC473;
      }
    }
  }

  ::v-deep .button {
    img {
      margin-right: 4px;
    }
  }
}

.input_box {
  height: 32px;
  line-height: 32px;
}
</style>